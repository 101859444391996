import React from 'react';
import {
  FirstColumn,
  SecondColumn,
  TwoColumnLayout,
} from '../components/layout-body';
import SearchableTripsList from '../components/SearchableTripsList/SearchableTripsList';
import TripFiltersFeature from '../features/TripFiltersFeature/TripFiltersFeature';
import useTripFilters from '../features/TripFiltersFeature/use-trip-filters';

const Dashboard: React.FC = (): React.ReactElement => {
  const { handleTripFiltersChange, tripFilters } = useTripFilters();

  return (
    <TwoColumnLayout protect>
      <FirstColumn sticky>
        <TripFiltersFeature
          hideHighestStatusFilter
          onFilterChange={handleTripFiltersChange}
        />
      </FirstColumn>

      <SecondColumn>
        <SearchableTripsList
          sourceUrl="Dashboard"
          searchTripFilters={tripFilters}
        />
      </SecondColumn>
    </TwoColumnLayout>
  );
};

export default Dashboard;
